body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.returnButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.returnButtonSecondary {
  padding: 4px 8px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #757575;
  font-family: Poppins;
  font-size: 12px;
  line-height: 1.33;
  border: solid 1px #cfd8dc;
}
.returnButtonSecondary:hover {
  background: rgba(0, 0, 0, 0.1);
}
.returnButtonSecondary img {
  margin-right: 4px;
}
